<script>
import { VxWalkthruStep } from "@/components/vx/VxWalkthru";
import CurrentReleaseStepDetail from "./CurrentReleaseStepDetail";

export default {
  name: "CurrentReleaseStep",
  components: {
    VxWalkthruStep,
    CurrentReleaseStepDetail,
  },
  props: {
    step: {
      type: Number,
      required: true,
    },
    release: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<template>
  <VxWalkthruStep :step="step">
    {{ $t("settings.softwareUpdate.currentReleaseStep.title") }}
    <template v-slot:detail>
      <CurrentReleaseStepDetail :release="release" />
    </template>
  </VxWalkthruStep>
</template>
