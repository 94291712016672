<script>
import { format, isDate } from "date-fns";
import { useNamespace } from "@/mixins/i18n";

export default {
  mixins: [useNamespace("settings.softwareUpdate.currentReleaseStep")],

  props: {
    release: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    working: false,
  }),

  computed: {
    releasedAt() {
      if (!isDate(this.release.createdAt)) return "";

      return format(this.release.createdAt, "M/d/yyyy");
    },
  },

  methods: {
    onSendFeedbackClick() {
      alert("send feedback");
    },
  },
};
</script>

<template>
  <div v-if="release.id">
    <h3 class="h3">Version {{ release.version }}</h3>
    <h4 class="text-subtitle-1">{{ releasedAt }}</h4>
    <p class="my-5" v-html="release.summary" />
    <v-btn
      outlined
      color="primary"
      :loading="working"
      class="my-5"
      :to="{
        name: 'currentRelease/feedback',
        params: { releaseId: release.id },
      }"
      >Send feedback</v-btn
    >
  </div>
  <div v-else>
    <p>{{ i18n("indeterminate") }}</p>
  </div>
</template>
